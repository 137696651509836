import './Footer.css';

export default function Footer() {

  return (
    <div className="Footer" id='Footer'>
        <div className="footerContainer">
            <p>© 2024 - 2025 - Made by Reda Grain.</p>
        </div>
    </div>
  );
}

